import { render, staticRenderFns } from "./DocumentItemPage.vue?vue&type=template&id=a8a42f22"
import script from "./DocumentItemPage.vue?vue&type=script&lang=js"
export * from "./DocumentItemPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports