<template>
  <div v-if="item">
    <v-card tile flat :class="isChild ? 'pb-0 pl-2 pr-2 pt-2' : 'pa-1'">
      <div class="d-flex flex-column">
        <div class="d-flex align-content-start">
          <div v-if="renderField(item.documentitemtypeid, 'number')" class="overline font-weight-light">
            {{ item.number }}
          </div>
          <div>
            <p v-if="showDocumentItemType && documentItemTypeName === 'Commentary'" class="pl-1 ma-0 overline font-weight-light">
              {{ item.englishtitle }}
            </p>
            <p v-else class="pl-1 ma-0 overline font-weight-light">{{ documentItemTypeName }}</p>
            <DocumentItemTranslations 
              :key="String(selectedLanguagesByDocument())+translations.length"
              :item="item"
              :translations="translations"
              :layout-in-rows="layoutInRows"
            />
            <div class="d-flex align-start">
              <div>
                <Connections :inout="item.connections_inout"/>
              </div>
              <div>
                <TermAssigned 
                  :data="item"
                />
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <DocumentItemMenu v-if="allowEdit(document.documenttypeid.name)" :document-item="item" />
        </div>
        <div 
          v-if="renderChildren" 
          v-for="child in getDocumentItemsByParent(item.documentitemid)" 
          :key="child.documentitemid"
        >
          <DocumentItem :item="child" :is-child="true" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import DocumentItemTranslations from '@/components/documentItemTranslations/DocumentItemTranslations'
import DocumentItemMenu from '@/components/documentItems/DocumentItemMenu'
import Connections from '@/components/connections/Connections'
import types from '@/helpers/types'
import TermAssigned from '@/components/terms/TermAssigned'
import DocAdmin from '@/store/data'

export default {
  name: 'DocumentItem',
  components: {
    DocumentItemTranslations,
    DocumentItemMenu,
    Connections,
    TermAssigned
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isChild: {
      type: Boolean,
      default: false
    },
    renderChildren: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    translations: []
  }),
  computed: {
    ...mapState('documentItems', ['documentItem', 'documentItems', 'selectedLanguages']),
    ...mapState('documents', ['selectedLanguages']),
    ...mapGetters('documentItems', ['getDocumentItemTranslations', 'getDocumentItemsByParent', 'getDocumentItemTranslationsByLanguage']),
    ...mapState('documentItems', ['isLeafNode']),
    ...mapState('documents', ['document', 'dataStructure']),
    ...mapGetters('documents', ['getDocumentDataStructure', 'getAllowedChildrenById', 'renderComponent', 'renderField', 'selectedLanguagesByDocument', 'getDocumentItemDataStructure']),
    ...mapGetters('account', ['allowEdit']),
    documentItemTypeName () {
      return types.getDocumentItemTypeNameById(this.item.documentitemtypeid)
    },
    showDocumentItemType () {
      const ditStructure = this.getDocumentItemDataStructure(this.documentItemTypeName)
      
      return ditStructure && !ditStructure.canBeReferenced
    },
    allowedChildren() {
      return this.getAllowedChildrenById(this.item.documentitemtypeid)
    },
    layoutInRows() {
      if (this.documentItemTypeName.toLowerCase() === 'prose' || 
          this.documentItemTypeName.toLowerCase() === 'preface' || 
          this.documentItemTypeName.toLowerCase() === 'shlok') {
        return false
      }

      return true
    }
  },
  watch: {
    selectedLanguages: {
      handler: function (val) {
        console.log(`selectedLanguagesByDocument=${val}`)
        this.translations = this.documentItemTranslationsLocal(this.item)
      },
      deep: true
    }
  },
  mounted() {
    this.translations = this.documentItemTranslationsLocal(this.item)
  },
  methods: {

    async getGroupedItems(groupedData) {
      return groupedData.map((group) => {
        return DocAdmin.documentItemTranslation.list({ params: { 
          documentitemid: group.destination_documentitemid,
          languageid: group.languageid
        } })
      })
    },

    documentItemTranslationsLocal(item) {

      // if items are grouped get the grouped items and add them to the list
      if (item.grouping_data?.separateParagraphs.length > 0) {
        const items = this.getDocumentItemTranslationsByLanguage(item.documentitemid, this.selectedLanguagesByDocument())
        
        this.getGroupedItems(item.grouping_data.separateParagraphs).then((results) => {
          Promise.all(results).then((r) => {
            r.forEach((data) => {
              data.data.results.forEach((result) => {
                items.push(result)
              })
            })
          })
        })

        return items
      }

      // preface hack for now, show all languages
      if (this.documentItemTypeName.toLowerCase() === 'prose' || this.documentItemTypeName.toLowerCase() === 'preface') {
        return this.getDocumentItemTranslations(item.documentitemid)
      }

      return this.getDocumentItemTranslationsByLanguage(item.documentitemid, this.selectedLanguagesByDocument())
    }
  }
}
</script>
<style scoped>
.vertical-text {
  vertical-align: top;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
</style>